.legal-overlay-container {
  padding: 0 1.5em;
  button {
    display: block;
    margin: 1em auto;
    min-width: 10em;
  }
}

.legal-overlay-centered {
  text-align: center;
}

.consent-success {
  margin: 6em auto 16em;
}

#cboxOverlay {
  &.legal-background {
    background-color: $color-black;
  }
}
/* uncomment this section to enable the "new"
 * indicator for the privacy-policy link
.site-footer {
  .sitewide-footer-formatter__term-link {
    &:has(a[href='/privacy-policy']) {
      &::after {
        content: '\65B0';
        zoom: 0.9;
        position: relative;
        top: -0.3em;
        left: 0.2em;
        color: $red;
      }
    }
  }
}
*/
