.spp-engraving-preview {
  .engrave-placement {
    top: 160px;
    left: -75px;
    @include breakpoint($landscape-up) {
      top: 445px;
      left: 450px;
    }
  }
}

.engraving-form {
  ul.error_messages {
    @include breakpoint($landscape-up) {
      top: 10%;
    }
  }
}
