.sitewide-footer-formatter {
  &__trustmark {
    display: none;
  }
  &__trustmark-wrapper {
    &.trustmark-logo-wrapper {
      clear: both;
      display: block;
      margin: 0;
      padding: 20px 0;
      text-align: center;
      @include breakpoint($landscape-up) {
        margin: 0 auto 0 10px;
        order: 4;
        padding: 0;
        width: 70%;
      }
    }
    img {
      margin: 0;
    }
  }
  &__global {
    display: inline-block;
    width: 100%;
  }
  &__regulatory-info {
    float: $ldirection;
    @include breakpoint($medium-up) {
      float: $rdirection;
    }
    .beian-links {
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-#{$ldirection}: 20px;
      }
      a {
        float: $ldirection;
        margin-#{$rdirection}: 5px;
        &.text-link {
          margin-top: 14px;
        }
      }
      img.logo-img {
        margin: -8px 0 0 10px;
      }
    }
  }
  .footer-country-link {
    @include breakpoint($landscape-up) {
      width: auto;
    }
  }
  &__terms-links {
    &.footer-links {
      margin: 0;
    }
  }
  .footer-language-toggle {
    .active {
      text-decoration: underline;
    }
  }
}
