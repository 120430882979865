.elc-product-full {
  .elc-size-picker-container {
    .slick-track {
      flex-wrap: wrap;
    }
    .slick-slide {
      &:first-child {
        padding-left: 20px;
      }
    }
  }
  &-cta-wrapper {
    .sd-product-spp & {
      .temporarily-out-of-stock {
        .elc-add-to-bag-button {
          background-color: $color-primary-300;
          cursor: not-allowed;
          color: $color-grey-disabled;
        }
      }
    }
  }
}
